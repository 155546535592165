import classNames from "classnames"

export interface CardProps {
    cardClass?: string
    children: any
    shadow?: boolean
    variant?: string
}

export const Card = ({
    cardClass = '',
    children,
    shadow,
    variant ="tradeCard"
}: CardProps) => {
    return (
        <div className={`card ${cardClass} ${shadow ? 'card-shadow' : ''}`}>
            {children}
        </div>
    )
}
