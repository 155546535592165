import React from "react";

export interface SvgIconProps{
  width : number;
  height : number;
  fill : string;
  className?: string;
  viewBox?: string;
  children : any;
}

export const SvgIcon = ({
   width,
   height,
   fill,
   className,
   viewBox,
   children
}: SvgIconProps) => {
  return (
    <svg
      // xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fill}
      className={className}
      viewBox={viewBox}
    >
      {children}
    </svg>
  );
};
