import { HTMLAttributes } from 'react'

export interface BreadcrumbProps extends HTMLAttributes<HTMLElement> {
  disabled?: boolean
  separator?: string
  data?: any
}
export const Breadcrumb = ({
  disabled,
  className,
  separator,
  data,
  ...props
}: BreadcrumbProps) => {
  const length = data.length
  return (
    <>
      <nav className="relative w-full navbar navbar-expand-lg navbar-light">
        <div className="container-fluid w-full flex flex-wrap items-center justify-between">
          <nav className="bg-grey-light rounded w-full" aria-label="breadcrumb">
            <ol className="list-reset flex">
              {data.map((items: any, i: number) => {
                return i < length - 1 ? (
                  <>
                    <li className="text-sm text-theme-neutralLighter20 text-capitalize">
                      <a href={items.url}>{items.item}</a>
                    </li>
                    <li className="text-theme-neutralLighter20 mx-2 text-sm">{separator}</li>
                  </>
                ) : (
                  <>
                    <li className="font-normal text-capitalize text-sm text-theme-neutralLighter20">
                      {items.item}
                    </li>
                  </>
                )
              })}
            </ol>
          </nav>
        </div>
      </nav>
    </>
  )
}
