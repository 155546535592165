import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Button } from './atoms/button';
import { Dashboard } from './page/dashboard/dashboard';

function App() {
  return (
    <div className='App'>
      <Dashboard/>
    </div>
  );
}

export default App;
