import { HTMLAttributes, useState, useEffect } from "react";
import { Button } from "../../atoms/button";
import { Input } from "../../atoms/input";
import { Loader } from "../../atoms/loader";
import { ReactStepper } from "../../atoms/stepper";
import { LedgerServiceFactory } from "../../ledger-service";
import { Modal } from "./modal";
import {formatBalance} from "../../helpers/AmountUtil";

export interface BuyModalProps extends HTMLAttributes<HTMLElement> {
    isVisible?: boolean;
    onClose?: any;
    celebId: number;
    celebName: string;
    celebSymbol: string;
}

export const BuyModal = ({
    isVisible,
    onClose,
    celebId,
    celebName,
    celebSymbol,
    ...props
}: BuyModalProps) => {
    const [showModal, setShowModal] = useState(isVisible);
    const [showLoader, setShowLoader] = useState(false);
    const [showStepper, setShowStepper] = useState(false);
    const [talBalance, setTalBalance] = useState(0);

    const [cstConvertedValue, setCstConvertedValue] = useState(0);

    const ledgerService = LedgerServiceFactory.getInstance();

    const [talDepositAmt, setTalDepositAmt] = useState(0);


    useEffect(() => {
        setShowModal(isVisible);
        if (isVisible) {
            setCstConvertedValue(0);
            setTalDepositAmt(0);
            ledgerService.getTALTokenBalance().then((result) => {
                console.log(result);
                setTalBalance(+result);
            });
        }
    }, [isVisible]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(async () => {
            if (talDepositAmt != 0) {
                const returnAmt = await ledgerService.calculatePurchaseReturn(celebId, talDepositAmt);
                setCstConvertedValue(+returnAmt);
            }
        }, 1000);
        return () => clearTimeout(delayDebounceFn);
    }, [talDepositAmt]);

    function onInputChange(event: any) {
        let value = +event.target.value
        if (value == 0) {
            setCstConvertedValue(0);
        }
        setTalDepositAmt(value);
    }

    async function buyCelebTokens() {
        // setShowStepper(true);
        setShowLoader(true);
        try {
            await ledgerService.approveTransfer(talDepositAmt);
            const result = await ledgerService.buy(celebId, talDepositAmt);
            handleModalClose({
                status: true,
                result,
                msg: <>
                    <p>Transaction Successful</p>
                    <p>Deposited <b style={{"color":"red"}}>{formatBalance(talDepositAmt)} TAL</b></p>
                    <p>Received <b style={{"color":"green"}}>{formatBalance(cstConvertedValue)} {celebSymbol}</b></p>
                </>
            });
        } catch (ex) {
            handleModalClose({ status: false, result: ex, msg: `Failed to purchase ${celebSymbol} token!!` });
        }
        setShowLoader(false);
    }

    function handleModalClose(event: any) {
        setShowModal(false);
        onClose(event);
    }

    return showModal ? (
        <>
            {showLoader ? <Loader loadFullPage /> : <></>}

            <Modal
                modalTitle={celebName}
                showClose={true}
                isVisible={showModal}
                onClose={() => {
                    setShowModal(false);
                    onClose();
                }}
            >
                <div className="flex items-center flex-col">
                    <p className="text-base uppercase mb-3">
                        <label className="text-base font-medium">available credit :</label>
                        {talBalance && <span>{formatBalance(talBalance)} TAL</span>}
                    </p>
                    <div className="flex align-center mb-3">
                        <div className="w-40 mr-3">
                            <Input
                                label="TAL"
                                placeholder="0"
                                type="text"
                                varient="small"
                                onChange={(event: any) => { onInputChange(event) }}
                            />
                        </div>
                        <div className="w-40">
                            <Input
                                label={celebSymbol}
                                placeholder="0"
                                type="text"
                                varient="small"
                                disabled={true}
                                value={formatBalance(cstConvertedValue)}
                            />
                        </div>
                    </div>

                    <Button
                        size="medium"
                        variant="primary"
                        className="w-80"
                        onClick={buyCelebTokens}>
                        Buy
                    </Button>

                    {showStepper && <div>
                        <ReactStepper />
                    </div>}
                </div>
            </Modal>
        </>
    ) : null;
};
