import React, { useEffect, useState } from 'react'
import { Button } from '../../atoms/button';
import { Loader } from '../../atoms/loader';
import { LedgerServiceFactory } from '../../ledger-service';
import { StatusModal } from '../../molecules/modal/status-modal';
import {formatBalance} from "../../helpers/AmountUtil";

function TopBar({firstMintReward}: any) {
    const [ledgerService] = useState(LedgerServiceFactory.getInstance());
    const [firstTimeRewardClaimed, setFirstTimeRewardClaimed] = useState(true);
    const [hasMintedAtLeastOnce, setHasMintedAtLeastOnce] = useState(false);
    const [firstTimeReward, setFirstTimeReward] = useState({ celebId: -1, rewardAmount: 0, claimed: true });
    const [statusModalData, setStatusModalData] = useState({ isVisible: false, isSuccess: false, msg: "", txHash: "" });
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        ledgerService.isFirstTimeRewardClaimed().then((claimed) => {
            setFirstTimeRewardClaimed(claimed);
        })
    }, [firstTimeRewardClaimed]);

    useEffect(() => {
        ledgerService.hasMintedAtleastOnce().then((hasMinted) => {
            setHasMintedAtLeastOnce(hasMinted);
        });
    }, [hasMintedAtLeastOnce]);

    useEffect(() => {
        ledgerService.getFirstTimeRewardDetails().then((reward) => {
            console.log("reward");
            setFirstTimeReward(reward);
        })
    }, [firstTimeRewardClaimed, hasMintedAtLeastOnce])

    useEffect(() => {
        console.log("First time reward enabled");
        console.log(firstMintReward);
        if (firstMintReward.celebId !== undefined) {
            setFirstTimeRewardClaimed(false);
            setHasMintedAtLeastOnce(true);
            firstMintReward.rewardAmount = firstMintReward.amount;
            setFirstTimeReward(firstMintReward);
        }

    }, [firstMintReward]);

    const claimFirstTimeReward = async () => {
        setShowLoader(true);
        ledgerService.claimFirstTimeReward().then((result: any) => {
            console.log("result" + result);
            if (result.receipt.status === true) {
                setFirstTimeRewardClaimed(true);
                showStatusModal(true, true, "Reward claimed successfully!!", result.receipt.transactionHash);
            }
            else {
                console.log("receipt: false")
                showStatusModal(true, false, "Failed to claim reward!!", result?.receipt?.transactionHash);
            }

        }).catch(() => {
            showStatusModal(true, false, "Transaction rejected", "");
        })
            .finally(() => {
                setShowLoader(false);
            });
    };

    function showStatusModal(isVisible: boolean, isSuccess: boolean, msg: string, txHash: string) {
        setStatusModalData({ isVisible, isSuccess, msg, txHash });
    }

    return (
        <>
            {showLoader ? <Loader loadFullPage /> : <></>}
            {
                !firstTimeRewardClaimed && hasMintedAtLeastOnce &&
                <div className="flex items-center justify-center p-5 m-1 top-bar">
                    <div className="flex items-center justify-center">
                        <p>
                            You are eligible for a reward of {formatBalance(firstTimeReward.rewardAmount)} TAL tokens for your first celebrity token purchase
                        </p>
                        <Button
                            isRounded
                            size="xs"
                            variant="primary"
                            className="mr-1 uppercase ml-5"
                            onClick={() => claimFirstTimeReward()}
                        >
                            claim now
                        </Button>
                    </div>
                </div>
            }
            <StatusModal
                isVisible={statusModalData.isVisible}
                onClose={() => showStatusModal(false, false, "", "")}
                varient={statusModalData.isSuccess ? "success" : "danger"}
                message={statusModalData.msg}
                txHash={statusModalData.txHash}
            ></StatusModal>
        </>
    )
}

export default TopBar;