import Web3 from 'web3';
import HDWalletProvider from '@truffle/hdwallet-provider';
import { AbiItem } from 'web3-utils';

declare let window: any;
const celebstarAbi = require('./assets/celebstar-abi.json');
const talAbi = require('./assets/tal-abi.json');
const celebstarContractAddress = process.env.REACT_APP_CELEBSTAR_CONTRACT_ADDRESS as string;
const talContractAddress = process.env.REACT_APP_TAL_CONTRACT_ADDRESS as string;

export class LedgerServiceFactory {
    static instance: LedgerService;
    static getInstance() {
        if (!LedgerServiceFactory.instance) {
            LedgerServiceFactory.instance = new LedgerService();
        }
        return LedgerServiceFactory.instance;
    }
}

const CHAIN_ID = (process.env.REACT_APP_CHAIN_ID as string);
const CHAIN_SWITCH_ALERT = (process.env.REACT_APP_CHAIN_SWITCH_ALERT as string);

class LedgerService {

    web3!: Web3;
    selectedAccounts!: string[];
    // accountChange = new Subject<void>();

    SCHEDULED_REWARD_DURATION = +(process.env.REACT_APP_SCHEDULED_REWARD_DURATION as string);
    SCHEDULED_REWARD_AMOUNT = +(process.env.REACT_APP_SCHEDULED_REWARD_AMOUNT as string);

    constructor() { }

    async fetchPlayersData(data: any[]) {
        for (let i = 0; i < data.length; i++) {
            const balance = await this.getCelebStarTokenBalance(data[i].id);
            data[i].balance = balance;
        }
        return data;
    }

    private async getWeb3Instance(): Promise<Web3> {
        if (this.web3 == undefined) {
            if (window.ethereum) {
                return new Web3(window.ethereum);
            } else {
                throw new Error("Metamask not detected!!. Please connect to metamask wallet");
            }
        }
        return this.web3;
    }

    async getSelectedAccount(): Promise<string[]> {
        if (this.selectedAccounts) {
            return this.selectedAccounts;
        }
        if (window.ethereum) {
            const accounts = await window.ethereum.request({
                method: "eth_requestAccounts",
            });
            this.selectedAccounts = accounts;
        } else {
            throw new Error("Metamask not detected!!. Please connect to metamask wallet");
        }
        return this.selectedAccounts;
    }

    async initMetamask() {
        this.getSelectedAccount();
        window.ethereum.on('accountsChanged', (accounts: string[]) => {
            this.handleAccountChange(accounts);
            console.log("Selected accounts Changed to - ", this.selectedAccounts);
        });

        const chainId = await window.ethereum.request({ method: 'eth_chainId' });
        if (chainId != CHAIN_ID) {
            alert(CHAIN_SWITCH_ALERT);
        }

        window.ethereum.on('chainChanged', (chainId: any) => {
            if (chainId != CHAIN_ID) {
                alert(CHAIN_SWITCH_ALERT);
            }
        });
    }

    private async handleAccountChange(accounts: string[]) {
        this.selectedAccounts = accounts;
        // this.accountChange.next();
    }

    async approveTransfer(talDepositAmt: number) {
        let web3 = await this.getWeb3Instance();
        let talContract = new web3.eth.Contract(talAbi, talContractAddress);
        const account = (await this.getSelectedAccount())[0];
        const weiAmount = web3.utils.toWei(talDepositAmt.toString(), 'ether');
        return new Promise((resolve, reject) => {
            try {
                talContract.methods.approve(celebstarContractAddress, weiAmount)
                    .send({ from: account })
                    .on('transactionHash', (hash: any) => {
                        console.log('transactionHash', hash);
                    })
                    .on('receipt', (receipt: any) => {
                        console.log('receipt', receipt);
                        resolve({ receipt: receipt })
                    })
                    .on('error', (error: any, receipt: any) => { reject({ error, receipt }) });
            } catch (ex) {
                reject(ex);
            }
        })
    }

    async buy(celebId: number, talDepositAmt: number) {
        let web3 = await this.getWeb3Instance();
        let contract = new web3.eth.Contract(celebstarAbi, celebstarContractAddress);
        const selectedAccounts = await this.getSelectedAccount();
        const weiAmount = web3.utils.toWei(talDepositAmt.toString(), 'ether');
        return new Promise((resolve, reject) => {
            try {
                contract.methods.mint(celebId, weiAmount)
                    .send({ from: selectedAccounts[0] })
                    .on('transactionHash', (hash: any) => {
                        console.log('transactionHash', hash);
                    })
                    .on('receipt', (receipt: any) => {
                        console.log('receipt', receipt);
                        resolve({ receipt: receipt })
                    })
                    .on('error', (error: any, receipt: any) => { reject({ error, receipt }) });
            } catch (ex) {
                reject(ex);
            }
        })
    }

    async sell(celebId: number, cstBurnAmt: number) {
        let web3 = await this.getWeb3Instance();
        let contract = new web3.eth.Contract(celebstarAbi, celebstarContractAddress);
        const selectedAccounts = await this.getSelectedAccount();
        const weiAmount = web3.utils.toWei(cstBurnAmt.toString(), 'ether');
        return new Promise((resolve, reject) => {
            try {
                contract.methods.burn(celebId, weiAmount)
                    .send({ from: selectedAccounts[0] })
                    .on('transactionHash', (hash: any) => {
                        console.log('transactionHash', hash);
                    })
                    .on('receipt', (receipt: any) => {
                        console.log('receipt', receipt);
                        resolve({ receipt: receipt })
                    })
                    .on('error', (error: any, receipt: any) => { reject({ error, receipt }) });
            } catch (ex) {
                reject(ex);
            }
        })

    }

    async calculatePurchaseReturn(celebId: number, talDepositAmt: number) {
        let web3 = await this.getWeb3Instance();
        const weiAmount = web3.utils.toWei(talDepositAmt.toString(), 'ether');
        let contract = new web3.eth.Contract(celebstarAbi, celebstarContractAddress);
        const selectedAccounts = await this.getSelectedAccount();
        const returnAmt = await contract.methods.calculatePurchaseReturnAfterDeductingFee(celebId, weiAmount).call({ from: selectedAccounts[0] });
        return web3.utils.fromWei(returnAmt);
    }

    async calculateSellReturn(celebId: number, cstBurnAmt: number) {
        let web3 = await this.getWeb3Instance();
        const weiAmount = web3.utils.toWei(cstBurnAmt.toString(), 'ether');
        let contract = new web3.eth.Contract(celebstarAbi, celebstarContractAddress);
        const selectedAccounts = await this.getSelectedAccount();
        const sellAmt = await contract.methods.calculateSellReturnAfterDeductingFee(celebId, weiAmount).call({ from: selectedAccounts[0] });
        return web3.utils.fromWei(sellAmt);
    }

    async getCelebStarTokenBalance(celebId: number) {
        let web3 = await this.getWeb3Instance();
        let contract = new web3.eth.Contract(celebstarAbi, celebstarContractAddress);
        const selectedAccounts = await this.getSelectedAccount();
        const balanceInWei = await contract.methods.balanceOf(selectedAccounts[0], celebId).call();
        return web3.utils.fromWei(balanceInWei);
    }

    async getTALTokenBalance() {
        let web3 = await this.getWeb3Instance();
        const balanceInWei = await this.getAccountBalance(talAbi, talContractAddress);
        return web3.utils.fromWei(balanceInWei);
    }

    async getETHBalance(account?: string) {
        let web3 = await this.getWeb3Instance();
        const accountAddress = account ? account : (await this.getSelectedAccount())[0];
        const weiBalance = await web3.eth.getBalance(accountAddress);
        return web3.utils.fromWei(weiBalance);
    }

    async claimFirstTimeReward() {
        let web3 = await this.getWeb3Instance();
        let contract = new web3.eth.Contract(celebstarAbi, celebstarContractAddress);
        const selectedAccount = await this.getSelectedAccount();
        return new Promise((resolve, reject) => {
            try {
                contract.methods.claimFirstTimeReward(selectedAccount[0])
                    .send({ from: selectedAccount[0] })
                    .on('transactionHash', (hash: any) => {
                        console.log('transactionHash', hash);
                    })
                    .on('receipt', (receipt: any) => {
                        console.log('receipt', receipt);
                        resolve({ receipt: receipt })
                    })
                    .on('error', (error: any, receipt: any) => { reject({ error, receipt }) });
            } catch (ex) {
                reject(ex);
            }
        })

    }

    async isRewardActive() {
        let web3 = await this.getWeb3Instance();
        let contract = new web3.eth.Contract(celebstarAbi, celebstarContractAddress);
        const selectedAccounts = await this.getSelectedAccount();
        return await contract.methods.getActiveRewardId().call({ from: selectedAccounts[0] }); // Returns reward id if active otherwise throws error
    }

    async isRewardClaimed(rewardId: number) {
        let web3 = await this.getWeb3Instance();
        let contract = new web3.eth.Contract(celebstarAbi, celebstarContractAddress);
        const selectedAccounts = await this.getSelectedAccount();
        return await contract.methods.isRewardClaimed(selectedAccounts[0], rewardId).call({ from: selectedAccounts[0] });
    }

    async getRewardDetails(rewardId: number) {
        let web3 = await this.getWeb3Instance();
        let contract = new web3.eth.Contract(celebstarAbi, celebstarContractAddress);
        const selectedAccounts = await this.getSelectedAccount();
        const reward = await contract.methods.rewards(rewardId).call({ from: selectedAccounts[0] });
        return {
            ...reward,
            totalRewardAmount: web3.utils.fromWei(reward.totalRewardAmount),
            totalRewardTokenSupply: web3.utils.fromWei(reward.totalRewardTokenSupply),
        };
    }

    async claimRewards() {
        let web3 = await this.getWeb3Instance();
        let contract = new web3.eth.Contract(celebstarAbi, celebstarContractAddress);
        const selectedAccount = await this.getSelectedAccount();
        return new Promise((resolve, reject) => {
            try {
                contract.methods.claimRewards(selectedAccount[0])
                    .send({ from: selectedAccount[0] })
                    .on('transactionHash', (hash: any) => {
                        console.log('transactionHash', hash);
                    })
                    .on('receipt', (receipt: any) => {
                        console.log('receipt', receipt);
                        resolve({ receipt: receipt })
                    })
                    .on('error', (error: any, receipt: any) => { reject({ error, receipt }) });
            } catch (ex) {
                reject(ex);
            }
        })

    }

    async isFirstTimeRewardClaimed() {
        let web3 = await this.getWeb3Instance();
        let contract = new web3.eth.Contract(celebstarAbi, celebstarContractAddress);
        const selectedAccounts = await this.getSelectedAccount();
        return await contract.methods.isFirstTimeRewardClaimed(selectedAccounts[0]).call({ from: selectedAccounts[0] });
    }

    async hasMintedAtleastOnce() {
        let web3 = await this.getWeb3Instance();
        let contract = new web3.eth.Contract(celebstarAbi, celebstarContractAddress);
        const selectedAccounts = await this.getSelectedAccount();
        return await contract.methods.hasMintedAtleastOnce(selectedAccounts[0]).call({ from: selectedAccounts[0] });
    }

    async getFirstTimeRewardDetails() {
        let web3 = await this.getWeb3Instance();
        let contract = new web3.eth.Contract(celebstarAbi, celebstarContractAddress);
        const selectedAccounts = await this.getSelectedAccount();
        const reward = await contract.methods.accountFirstTimeReward(selectedAccounts[0]).call();
        return { ...reward, rewardAmount: web3.utils.fromWei(reward.rewardAmount) };
    }

    async getCountOfRegisteredCelebrities() {
        let web3 = await this.getWeb3Instance();
        let contract = new web3.eth.Contract(celebstarAbi, celebstarContractAddress);
        return await contract.methods.getCountOfRegisteredCelebrities().call();
    }

    private async getAccountBalance(abi: AbiItem, deployedContractAddress: string) {
        let web3 = await this.getWeb3Instance();
        let contract = new web3.eth.Contract(abi, deployedContractAddress);
        const selectedAccounts = await this.getSelectedAccount();
        return await contract.methods.balanceOf(selectedAccounts[0]).call();
    }

    async buyTALToken(ethAmount: number) {
        let web3 = await this.getWeb3Instance();
        let contract = new web3.eth.Contract(talAbi, talContractAddress);
        const selectedAccounts = await this.getSelectedAccount();
        const weiAmount = web3.utils.toWei(ethAmount.toString(), 'ether');

        return new Promise((resolve, reject) => {
            try {
                contract.methods.mintTalToken()
                    .send({ from: selectedAccounts[0], value: weiAmount })
                    .on('transactionHash', (hash: any) => {
                        console.log('transactionHash', hash);
                    })
                    .on('receipt', (receipt: any) => {
                        console.log('receipt', receipt);
                        resolve({ receipt: receipt })
                    })
                    .on('error', (error: any, receipt: any) => { reject({ error, receipt }) });
            } catch (ex) {
                reject(ex);
            }
        })
    }

    async getRewardFundBalance() {
        let web3 = await this.getWeb3Instance();
        let contract = new web3.eth.Contract(celebstarAbi, celebstarContractAddress);
        const selectedAccounts = await this.getSelectedAccount();
        const balanceInWei = await contract.methods.availableRewardFunds().call({ from: selectedAccounts[0] });
        return web3.utils.fromWei(balanceInWei);
    }

}
