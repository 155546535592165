import { HTMLAttributes, useEffect, useState } from "react";
import { LedgerServiceFactory } from "../../ledger-service";
import { Modal } from "../../molecules/modal";
import { BuyTALModal } from "../../molecules/modal/buy-tal-modal";
import { StatusModal } from "../../molecules/modal/status-modal";
import { Button } from "../button";

export interface HeaderProps extends HTMLAttributes<HTMLElement> {
  itemsPosition?: string;
  contentPosition?: string;
  padding?: string;
  margin?: string;
}

export const Header = ({ ...props }: HeaderProps) => {
  const [statusModalData, setStatusModalData] = useState({ isVisible: false, isSuccess: false, msg: "", txHash: "" });
  const [showBuyTALModal, setShowBuyTALModal] = useState(false);

  function showStatusModal(isVisible: boolean, isSuccess: boolean, msg: string, txHash: string) {
    setStatusModalData({ isVisible, isSuccess, msg, txHash });
  }

  function handleModalClose(event: any) {
    setShowBuyTALModal(false);
    if (event && event.status != undefined) {
      if (event.status) {
        showStatusModal(true, true, "Purchased TAL token successfully!!", event.result.receipt.transactionHash);
      } else {
        showStatusModal(true, false, "Failed to purchase TAL token!!", event?.result?.receipt?.transactionHash);
      }
    }
    console.log(event);
  }

  return (
    <>
      <div className="flex items-center justify-between py-3 mb-1">
        <div className="font-bold text-theme-neutralLighter20">
          <Button
            isRounded
            size="xs"
            variant="secondary"
            className="mr-1 uppercase"
            onClick={() => setShowBuyTALModal(true)}
          >
            Get TAL Tokens
          </Button>
        </div>
      </div>
      <BuyTALModal
        isVisible={showBuyTALModal}
        onClose={(event: any) => handleModalClose(event)}></BuyTALModal>

      <StatusModal
        isVisible={statusModalData.isVisible}
        onClose={() => showStatusModal(false, false, "", "")}
        varient={statusModalData.isSuccess ? "success" : "danger"}
        message={statusModalData.msg}
        txHash={statusModalData.txHash}
      ></StatusModal>
    </>
  );
};
