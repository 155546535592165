interface CardBodyProps {
    cardBodyClass?: string
    children: any
}

export const CardBody = ({
    cardBodyClass = "p-2",
    children
}: CardBodyProps) => {
    return (
        <div className={`card-body ${cardBodyClass}`}>
            {children}
        </div>
    )
}