import { HTMLAttributes, useState, useEffect } from "react";
import { Button } from "../../atoms/button";
import { Input } from "../../atoms/input";
import { Loader } from "../../atoms/loader";
import { ReactStepper } from "../../atoms/stepper";
import { LedgerServiceFactory } from "../../ledger-service";
import { Modal } from "./modal";
import {formatBalance} from "../../helpers/AmountUtil";

export interface SellModalProps extends HTMLAttributes<HTMLElement> {
    isVisible?: boolean;
    onClose?: any;
    celebId: number;
    celebName: string;
    celebSymbol: string;
}

export const SellModal = ({
    isVisible,
    onClose,
    celebId,
    celebName,
    celebSymbol,
    ...props
}: SellModalProps) => {
    const [showModal, setShowModal] = useState(isVisible);
    const [showLoader, setShowLoader] = useState(false);
    const [cstBalance, setCstBalance] = useState(0);

    const [talConvertedValue, setTalConvertedValue] = useState(0);

    const ledgerService = LedgerServiceFactory.getInstance();

    const [cstDepositAmt, setCstDepositAmt] = useState(0);


    useEffect(() => {
        setShowModal(isVisible);
        if (isVisible) {
            setTalConvertedValue(0);
            setCstDepositAmt(0);
            ledgerService.getCelebStarTokenBalance(celebId).then((result) => {
                console.log(result);
                setCstBalance(+result);
            });
        }
    }, [isVisible]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(async () => {
            if (cstDepositAmt != 0) {
                const returnAmt = await ledgerService.calculateSellReturn(celebId, cstDepositAmt);
                setTalConvertedValue(+returnAmt);
            }
        }, 1000);
        return () => clearTimeout(delayDebounceFn);
    }, [cstDepositAmt]);

    function onInputChange(event: any) {
        let value = +event.target.value
        if (value == 0) {
            setTalConvertedValue(0);
        }
        setCstDepositAmt(value);
    }

    async function sellCelebTokens() {
        // setShowStepper(true);
        setShowLoader(true);
        try {
            const result = await ledgerService.sell(celebId, cstDepositAmt);
            handleModalClose({
                status: true,
                result,
                msg: <>
                    <p>Transaction Successful</p>
                    <p>Sold <b style={{"color":"red"}}>{formatBalance(cstDepositAmt)} {celebSymbol}</b></p>
                    <p>Received <b style={{"color":"green"}}>{formatBalance(talConvertedValue)} TAL</b></p>
                </>
            });
        } catch (ex) {
            handleModalClose({ status: false, result: ex, msg: "Sell transaction failed!" });
        }
        setShowLoader(false);
    }

    function handleModalClose(event: any) {
        setShowModal(false);
        onClose(event);
    }

    return showModal ? (
        <>
            {showLoader ? <Loader loadFullPage /> : <></>}

            <Modal
                modalTitle={celebName}
                showClose={true}
                isVisible={showModal}
                onClose={() => {
                    setShowModal(false);
                    onClose();
                }}
            >
                <div className="flex items-center flex-col">
                    <p className="text-base uppercase mb-3">
                        <label className="text-base font-medium">{celebSymbol} Balance :</label>
                        {cstBalance && <span>{formatBalance(cstBalance)}</span>}
                    </p>
                    <div className="flex align-center mb-3">
                        <div className="w-40 mr-3">
                            <Input
                                label={celebSymbol}
                                placeholder="0"
                                type="text"
                                varient="small"
                                onChange={(event: any) => { onInputChange(event) }}
                            />
                        </div>
                        <div className="w-40">
                            <Input
                                label="TAL"
                                placeholder="0"
                                type="text"
                                varient="small"
                                disabled={true}
                                value={formatBalance(talConvertedValue)}
                            />
                        </div>
                    </div>

                    <Button
                        size="medium"
                        variant="primary"
                        className="w-80"
                        onClick={sellCelebTokens}>
                        Sell
                    </Button>
                </div>
            </Modal>
        </>
    ) : null;
};
