import { HTMLAttributes, useState, useEffect } from "react";
import classNames from "classnames";
import { Button } from "../../atoms/button";
import Icon from "../../atoms/icons/icon";
import { ModalFooter } from "./modalFooter";

export interface ModalProps extends HTMLAttributes<HTMLElement> {
  size?: "small" | "medium" | "large";
  varient?: "danger" | "success";
  showFooter?: boolean;
  showClose?: boolean;
  title?: string;
  isVisible?: boolean;
  onSave?: any;
  onClose?: any;
  footerChildren?: any;
  modalTitle?: any;
}

export const Modal = ({
  size = "medium",
  children,
  title,
  showFooter,
  showClose,
  className,
  onSave,
  isVisible,
  onClose,
  varient,
  footerChildren,
  modalTitle,
  ...props
}: ModalProps) => {
  const [showModal, setShowModal] = useState(isVisible);
  useEffect(() => {
    setShowModal(isVisible);
  }, [isVisible]);

  const saveClick = () => {
    setShowModal(false);
    onSave();
  };
  const closeModal = () => {
    setShowModal(false);
    onClose();
  };

  return showModal ? (
    <>
      <div className={"justify-center items-center fixed h-full w-full top-0 left-0 modal z-40"}>
        <div className="relative h-full w-full">
          <div
            className={classNames(
              "p-4 top-1/2 left-1/2 absolute transform -translate-x-1/2 -translate-y-1/2  max-w-100 z-50",
              size === "small" && "w-1/3",
              size === "medium" && "w-1/2",
              size === "large" && "w-full",
            )}
          >
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white">
              {modalTitle &&<div className="flex items-start justify-between px-4 py-2 border-b border-solid border-blueGray-200 rounded-t">
                <h2 className="text-xl font-medium flex justify-between items-center flex-1">
                  {modalTitle}
                  <span className="cursor-pointer opacity-40" onClick={() => closeModal()}>
                    <Icon kind="close" size={14}></Icon>
                  </span>
                </h2>
                {/* {showClose && (
                  <Icon
                    kind="close"
                    size={20}
                    className="cursor-pointer mt-0.5 mr-1"
                    onClick={() => closeModal()}
                  />
                )} */}
              </div>}
              <div className="p-4">
                <div className={classNames(
                  "modal-body overflow-y-auto"
                )}
                >{children}</div>
              </div>
              {showFooter && (
                <ModalFooter
                  modalFooterClass={classNames('')}
                > {footerChildren} </ModalFooter>
              )}
            </div>
          </div>
        </div>
        <div
          className="opacity-20 fixed inset-0 z-40 bg-black"
          onClick={() => closeModal()}
        ></div>
      </div>
    </>
  ) : null;
};
