import React, { useState } from "react";
import { Header } from "../../atoms/header";
import { MainHeader } from "../../components/mainheader";
import RewardBar from "../../components/reward/reward";
import { Sidebar } from "../../components/sidebar/sidebar";
import TopBar from "../../components/topBar/topBar";
import { PlayerCard } from "./playerCard";

export interface DashboardProps { }
export const Dashboard = () => {
  const [firstMintReward, setfirstMintReward] = useState({});
  const [rewardClaimedFromBuySell, setRewardClaimedFromBuySell] = useState(false);


  const onFirstTimeMint = (reward: any) => {
    console.log(reward);
    setfirstMintReward({celebId: reward.celebId, amount: reward.amount, claimed: false});
  };

  const onRewardClaim = (reward: any) => {
    console.log(reward);
    setRewardClaimedFromBuySell(true);
  };

  return (
    <main className="app-wrap h-full flex overflow-hidden">
      <div className="left-nav">
        <Sidebar />
      </div>
      <div className="w-full h-full flex flex-col">
        <div className="top-header bg-theme-mainBlue">
          <MainHeader />
        </div>
        <section className="w-full p-3 h-full overflow-auto">
          <TopBar firstMintReward={firstMintReward}/>
          <RewardBar rewardClaimedFromBuySell={rewardClaimedFromBuySell}/>
          <Header />
          <div className="grid grid-cols-3 gap-4">
            <PlayerCard onFirstTimeMint={onFirstTimeMint} onRewardClaim={onRewardClaim}/>
          </div>
        </section>
      </div>
    </main>
  );
};
