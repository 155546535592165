import { InputHTMLAttributes } from "react";
import Icon from "../icons/icon";
import { Loader } from "../loader";

export interface InputProps extends InputHTMLAttributes<HTMLButtonElement> {
  type: string;
  label?: string;
  feedbackText?: string;
  feedbackState?: any;
  varient?: "small" | "medium" | "large";
  disabled?: boolean;
  loadingInput?: boolean;
  feedbackIcon?: string;
  defaultValue?: any;
  onChange?: any;
  value?: any;
}

export const Input = ({
  type = "text",
  children,
  disabled,
  placeholder,
  feedbackText,
  feedbackState = "",
  label,
  varient = "medium",
  loadingInput,
  feedbackIcon,
  defaultValue,
  onChange,
  value,
  ...props
}: InputProps) => {
  return (
    <div className="form-group relative mb-4">
      {label && (
        <label className={`${disabled ? "text-theme-neutralLighter40" : ""}`}>
          {label}
        </label>
      )}
      <input
        type={type}
        value={value}
        className={`form-control mb-0 form-control-${varient} ${
          loadingInput ? "loading-input placeholder-opacity-0" : ""
        } ${feedbackState}`}
        placeholder={placeholder}
        disabled={disabled}
        defaultValue={defaultValue}
        onChange={onChange}
      />
      {loadingInput && (
        <div className="absolute top-1/2 w-full h-5 px-4 transform -translate-y-1/2">
          <Loader />
        </div>
      )}
      {feedbackIcon && (
        <span className={`feedback-icon ${feedbackState}`}>
          <Icon kind={feedbackIcon} size={12}/>
        </span>
      )}
      {feedbackState && feedbackText && (
        <span className={feedbackState + " text-xs"}>{feedbackText}</span>
      )}
    </div>
  );
};
