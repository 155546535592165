interface CardFooterProps {
    cardFooterClass?: string
    children: any
    bgColor?:boolean
}

export const CardFooter = ({
    cardFooterClass="p-2",
    children,
    bgColor,
}: CardFooterProps) => {
    return (
        <div className={`card-footer ${cardFooterClass} ${bgColor ? 'bg-theme-light' : ''}`}>
            {children}
        </div>
    )
}
