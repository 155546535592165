import React from "react";
import { SvgIcon } from "../../atoms/svgicons";

export interface SidebarProps {}

export const Sidebar = ({}: SidebarProps) => {
  return (
    <>
      <div className="min-h-screen h-full gradient-bg">
        <div className="flex justify-center items-center w-full h-12 px-3">
          <img
            src="https://www.talentica.com/wp-content/uploads/2021/09/Talentica-white-Logo.svg"
            alt=""
            style={{"background": "none", "width": "10.5rem"}}
            className="h-9 w-9 bg-white"
          />
        </div>
        <div className="flex flex-col px-3 ">
          <div className="flex justify-start align-center py-2">
            <h4 className="text-white font-medium">Profile</h4>
          </div>
          <div className="flex items-center gap-5 pl-2 py-1">
            <SvgIcon
              width={16}
              height={16}
              fill="white"
              className="bi bi-house-door-fill"
              viewBox="0 0 16 16"
              children={
                <>
                  <path d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5z" />
                </>
              }
            />
            <h5 className="text-white cursor-pointer">Dashboard</h5>
          </div>
          <div className="flex items-center gap-5 pl-2 py-1">
            <SvgIcon
              width={16}
              height={16}
              fill="white"
              className="bi bi-screwdriver"
              viewBox="0 0 16 16"
              children={
                <>
                  <path d="M0 .995.995 0l3.064 2.19a.995.995 0 0 1 .417.809v.07c0 .264.105.517.291.704l5.677 5.676.909-.303a.995.995 0 0 1 1.018.24l3.338 3.339a.995.995 0 0 1 0 1.406L14.13 15.71a.995.995 0 0 1-1.406 0l-3.337-3.34a.995.995 0 0 1-.24-1.018l.302-.909-5.676-5.677a.995.995 0 0 0-.704-.291H3a.995.995 0 0 1-.81-.417L0 .995Zm11.293 9.595a.497.497 0 1 0-.703.703l2.984 2.984a.497.497 0 0 0 .703-.703l-2.984-2.984Z" />
                </>
              }
            />
            <h5 className="text-white cursor-pointer">Cricket</h5>
          </div>
        </div>
        <div className="flex flex-col px-3 ">
          <div className="flex justify-start align-center py-2">
            <h4 className="text-white font-medium">Markets</h4>
          </div>

          <div className="flex items-center gap-5 pl-2 py-1">
            <SvgIcon
              width={16}
              height={16}
              fill="white"
              className="bi bi-screwdriver"
              viewBox="0 0 16 16"
              children={
                <>
                  <path d="M0 .995.995 0l3.064 2.19a.995.995 0 0 1 .417.809v.07c0 .264.105.517.291.704l5.677 5.676.909-.303a.995.995 0 0 1 1.018.24l3.338 3.339a.995.995 0 0 1 0 1.406L14.13 15.71a.995.995 0 0 1-1.406 0l-3.337-3.34a.995.995 0 0 1-.24-1.018l.302-.909-5.676-5.677a.995.995 0 0 0-.704-.291H3a.995.995 0 0 1-.81-.417L0 .995Zm11.293 9.595a.497.497 0 1 0-.703.703l2.984 2.984a.497.497 0 0 0 .703-.703l-2.984-2.984Z" />
                </>
              }
            />
            <h5 className="text-white cursor-pointer">Cricket</h5>
          </div>
        </div>
      </div>
    </>
  );
};
