import { HTMLAttributes, useState, useEffect } from "react";

export interface LoaderProps extends HTMLAttributes<HTMLElement> {
	loadFullPage?: boolean,
}

export const Loader = ({
	loadFullPage,
}: LoaderProps) => {
	return loadFullPage ? (
		<div style={{ "zIndex": 55 }} className={`animate-pulse fixed top-0 left-0 bg-gray-300 opacity-30 h-screen w-full flex items-center justify-center`}>
			<svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
				<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
				<path className="opacity-60" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
			</svg>
		</div>
	) : (
		<div className={`animate-pulse w-full h-full bg-theme-primary`}>
			<div className='loading h-full'></div>
		</div>
	)
}
