// DONOT MODIFY THIS FILE GENERATED BY YARN

import * as React from 'react'

const iconList = [
  'alarm',
  'arrow-right',
  'check-circle',
  'exclamation-circle',
  'hexagon',
  'search',
  'close',
  'star',
  'flag',
]

export interface IconProps {
  color?: string
  className?: string
  height?: number
  kind?: string | any
  onClick?: () => void
  preview?: boolean
  size?: number
  style?: any
  width?: number
  wrapperStyle?: any
}
export default class Icon extends React.Component<IconProps, {}> {
  static defaultProps = {
    className: '',
    color: 'currentColor',
    size: 32,
  }

  render() {
    const { kind, preview } = this.props
    return preview ? this.renderPreview() : this.renderIcon(kind)
  }

  renderPreview() {
    return (
      <div className="w-auto flex flex-wrap">
        {iconList.map((kind) => this.renderPreviewKind(kind))}
      </div>
    )
  }

  renderIcon(kind: string) {
    const { wrapperStyle } = this.props
    if (wrapperStyle) {
      return <div style={wrapperStyle}>{this.getIcon(kind)}</div>
    }
    return this.getIcon(kind)
  }

  renderPreviewKind(kind: string) {
    return (
      <div key={kind} className="w-1/4 px-2">
        <div className="p-4 my-2 bg-purple-100 text-purple-700 text-center rounded">
          <p className="mb-3 flex justify-center">{this.renderIcon(kind)}</p>
          <p>
            <small>&lt;Icon kind="{kind}" /&gt;</small>
          </p>
        </div>
      </div>
    )
  }

  getIcon(kind: string) {
    const {
      color,
      height,
      onClick,
      size,
      style,
      width,
      className,
      ...rest
    } = this.props

    switch (kind) {
      case 'alarm':
        return (
          <svg
            {...rest}
            height={height || size}
            width={width || size}
            onClick={onClick}
            style={style}
            className={className}
            fill={color}
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M5.5.5A.5.5 0 0 1 6 0h4a.5.5 0 0 1 0 1H9v1.07a7.002 7.002 0 0 1 3.537 12.26l.817.816a.5.5 0 0 1-.708.708l-.924-.925A6.967 6.967 0 0 1 8 16a6.967 6.967 0 0 1-3.722-1.07l-.924.924a.5.5 0 0 1-.708-.708l.817-.816A7.002 7.002 0 0 1 7 2.07V1H5.999a.5.5 0 0 1-.5-.5zM.86 5.387A2.5 2.5 0 1 1 4.387 1.86 8.035 8.035 0 0 0 .86 5.387zM13.5 1c-.753 0-1.429.333-1.887.86a8.035 8.035 0 0 1 3.527 3.527A2.5 2.5 0 0 0 13.5 1zm-5 4a.5.5 0 0 0-1 0v3.882l-1.447 2.894a.5.5 0 1 0 .894.448l1.5-3A.5.5 0 0 0 8.5 9V5z"
            />
          </svg>
        )
      case 'arrow-right':
        return (
          <svg
            {...rest}
            height={height || size}
            width={width || size}
            onClick={onClick}
            style={style}
            className={className}
            fill={color}
            viewBox="0 0 24 24"
          >
            <path
              d="M14 5l7 7m0 0l-7 7m7-7H3"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )
      case 'check-circle':
        return (
          <svg
            {...rest}
            height={height || size}
            width={width || size}
            onClick={onClick}
            style={style}
            className={className}
            fill={color}
            viewBox="0 0 24 24"
          >
            <path
              d="M9 12l2 2 4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0z"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )
      case 'exclamation-circle':
        return (
          <svg
            {...rest}
            height={height || size}
            width={width || size}
            onClick={onClick}
            style={style}
            className={className}
            fill={color}
            viewBox="0 0 24 24"
          >
            <path
              d="M12 8v4m0 4h.01M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0z"
              stroke="#4A5568"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )
      case 'hexagon':
        return (
          <svg
            {...rest}
            height={height || size}
            width={width || size}
            onClick={onClick}
            style={style}
            className={className}
            fill={color}
            viewBox="0 0 65 74"
          >
            <path d="M0 55.5v-37L33 0l32 18.5v37L33 74z" />
            <path d="M16 47.269V27.701l17.017-9.286L49 27.715V47.25l-15.984 9.3z" />
            <path d="M0 21.237l.97-1.7 26.238 14.11-.97 1.7z" />
            <path d="M23 33.849l.97-1.7 26.238 14.11-.97 1.7z" />
            <path d="M33 19.537l-16 8.731v18.433l16-8.731zM63 19.537l-16 8.731v19.4l16-8.731z" />
            <path d="M63 37.97l-16 8.731v18.433l16-8.731z" />
            <path d="M49 45.731l-16 8.731v18.433l16-8.731z" />
          </svg>
        )
      case 'search':
        return (
          <svg
            id="Layer_1"
            {...rest}
            height={height || size}
            width={width || size}
            onClick={onClick}
            style={style}
            className={className}
            fill={color}
            viewBox="0 0 16 16"
          >
            <path d="M11.435 10.063h-.723l-.256-.247a5.955 5.955 0 1 0-.64.64l.247.256v.723L14.637 16 16 14.637zm-5.489 0a4.117 4.117 0 1 1 4.117-4.117 4.111 4.111 0 0 1-4.117 4.117z" />
          </svg>
        )
      case 'close':
        return (
          <svg
            id="Layer_1"
            {...rest}
            height={height || size}
            width={width || size}
            onClick={onClick}
            style={style}
            className={className}
            fill={color}
            viewBox="0 0 16 16"
          >
            <path
              stroke="currentcolor"
              strokeWidth={2}
              d="M1 11 11 1M1 1l10 10"
            />
          </svg>
        )
      case 'bell':
        return (
          <svg
            id="Layer_1"
            {...rest}
            height={height || size}
            width={width || size}
            onClick={onClick}
            style={style}
            className={className}
            fill={color}
            viewBox="0 0 448 512"
          >
            <path
              fill="currentColor"
              d="M224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64zm215.39-149.71c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71z"
            />
          </svg>
        )
      case 'hamburger':
        return (
          <svg
            id="Layer_1"
            {...rest}
            height={height || size}
            width={width || size}
            onClick={onClick}
            style={style}
            className={className}
            fill={color}
            viewBox="0 0 448 512"
          >
            <path
              fill="currentColor"
              d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"
            />
          </svg>
        )
      case 'star':
        return (
          <svg
            id="Layer_1"
            {...rest}
            height={height || size}
            width={width || size}
            onClick={onClick}
            style={style}
            className={className}
            fill={color}
            viewBox="0 0 576 512"
          >
            <path
              fill="currentColor"
              d="M259.3 17.8 194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"
            />
          </svg>
        )
      case 'flag':
        return (
          <svg
            id="Layer_1"
            {...rest}
            height={height || size}
            width={width || size}
            onClick={onClick}
            style={style}
            className={className}
            fill={color}
            viewBox="0 0 640 480"
          >
            <path fill="#f93" d="M0 0h640v160H0z" />
            <path fill="#fff" d="M0 160h640v160H0z" />
            <path fill="#128807" d="M0 320h640v160H0z" />
            <g transform="matrix(3.2 0 0 3.2 320 240)">
              <circle r={20} fill="#008" />
              <circle r={17.5} fill="#fff" />
              <circle r={3.5} fill="#008" />
              <g id="d">
                <g id="c">
                  <g id="b">
                    <g id="a" fill="#008">
                      <circle r={0.9} transform="rotate(7.5 -8.8 133.5)" />
                      <path d="M0 17.5.6 7 0 2l-.6 5L0 17.5z" />
                    </g>
                    <use
                      width="100%"
                      height="100%"
                      transform="rotate(15)"
                      xlinkHref="#a"
                    />
                  </g>
                  <use
                    width="100%"
                    height="100%"
                    transform="rotate(30)"
                    xlinkHref="#b"
                  />
                </g>
                <use
                  width="100%"
                  height="100%"
                  transform="rotate(60)"
                  xlinkHref="#c"
                />
              </g>
              <use
                width="100%"
                height="100%"
                transform="rotate(120)"
                xlinkHref="#d"
              />
              <use
                width="100%"
                height="100%"
                transform="rotate(-120)"
                xlinkHref="#d"
              />
            </g>
          </svg>
        )

        switch (kind) {
          case 'alarm':
            return (
              <svg
                {...rest}
                height={height || size}
                width={width || size}
                onClick={onClick}
                style={style}
                className={className}
                fill={color}
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M5.5.5A.5.5 0 0 1 6 0h4a.5.5 0 0 1 0 1H9v1.07a7.002 7.002 0 0 1 3.537 12.26l.817.816a.5.5 0 0 1-.708.708l-.924-.925A6.967 6.967 0 0 1 8 16a6.967 6.967 0 0 1-3.722-1.07l-.924.924a.5.5 0 0 1-.708-.708l.817-.816A7.002 7.002 0 0 1 7 2.07V1H5.999a.5.5 0 0 1-.5-.5zM.86 5.387A2.5 2.5 0 1 1 4.387 1.86 8.035 8.035 0 0 0 .86 5.387zM13.5 1c-.753 0-1.429.333-1.887.86a8.035 8.035 0 0 1 3.527 3.527A2.5 2.5 0 0 0 13.5 1zm-5 4a.5.5 0 0 0-1 0v3.882l-1.447 2.894a.5.5 0 1 0 .894.448l1.5-3A.5.5 0 0 0 8.5 9V5z"
                />
              </svg>
            )
          case 'arrow-right':
            return (
              <svg
                {...rest}
                height={height || size}
                width={width || size}
                onClick={onClick}
                style={style}
                className={className}
                fill={color}
                viewBox="0 0 24 24"
              >
                <path
                  d="M14 5l7 7m0 0l-7 7m7-7H3"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )
          case 'check-circle':
            return (
              <svg
                {...rest}
                height={height || size}
                width={width || size}
                onClick={onClick}
                style={style}
                className={className}
                fill={color}
                viewBox="0 0 24 24"
              >
                <path
                  d="M9 12l2 2 4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0z"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )
          case 'search':
            return (
              <svg
                id="Layer_1"
                {...rest}
                height={height || size}
                width={width || size}
                onClick={onClick}
                style={style}
                className={className}
                fill={color}
                viewBox="0 0 16 16"
              >
                <path d="M11.435 10.063h-.723l-.256-.247a5.955 5.955 0 1 0-.64.64l.247.256v.723L14.637 16 16 14.637zm-5.489 0a4.117 4.117 0 1 1 4.117-4.117 4.111 4.111 0 0 1-4.117 4.117z" />
              </svg>
            )
          case 'close':
            return (
              <svg
                {...rest}
                height={height || size}
                width={width || size}
                onClick={onClick}
                style={style}
                className={className}
                fill={color}
                viewBox="0 0 16 16"
              >
                <path d="M16 1.611L14.389 0 8 6.389 1.611 0 0 1.611 6.389 8 0 14.389 1.611 16 8 9.611 14.389 16 16 14.389 9.611 8z" />
              </svg>
            )
          case 'exclamation-circle':
            return (
              <svg
                {...rest}
                height={height || size}
                width={width || size}
                onClick={onClick}
                style={style}
                className={className}
                fill={color}
                viewBox="0 0 24 24"
              >
                <path
                  d="M12 8v4m0 4h.01M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0z"
                  stroke="#4A5568"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )
          case 'hexagon':
            return (
              <svg
                {...rest}
                height={height || size}
                width={width || size}
                onClick={onClick}
                style={style}
                className={className}
                fill={color}
                viewBox="0 0 65 74"
              >
                <path d="M0 55.5v-37L33 0l32 18.5v37L33 74z" />
                <path d="M16 47.269V27.701l17.017-9.286L49 27.715V47.25l-15.984 9.3z" />
                <path d="M0 21.237l.97-1.7 26.238 14.11-.97 1.7z" />
                <path d="M23 33.849l.97-1.7 26.238 14.11-.97 1.7z" />
                <path d="M33 19.537l-16 8.731v18.433l16-8.731zM63 19.537l-16 8.731v19.4l16-8.731z" />
                <path d="M63 37.97l-16 8.731v18.433l16-8.731z" />
                <path d="M49 45.731l-16 8.731v18.433l16-8.731z" />
              </svg>
            )
          default:
            return null
        }
    }
  }
}
