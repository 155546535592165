import { HTMLAttributes, useState, useEffect } from "react";
import { Button } from "../../atoms/button";
import { Input } from "../../atoms/input";
import { Loader } from "../../atoms/loader";
import { ReactStepper } from "../../atoms/stepper";
import { LedgerServiceFactory } from "../../ledger-service";
import { Modal } from "./modal";

export interface StatusModalProps extends HTMLAttributes<HTMLElement> {
    isVisible?: boolean;
    onClose?: any;
    varient?: "danger" | "success";
    message?: any;
    txHash?: string;
}

export const StatusModal = ({
    isVisible,
    onClose,
    varient,
    message,
    txHash,
    ...props
}: StatusModalProps) => {
    const [showModal, setShowModal] = useState(isVisible);

    useEffect(() => {
        setShowModal(isVisible);
    }, [isVisible]);

    const closeModal = () => {
        setShowModal(false);
        onClose();
    };
    
    return showModal ? (
        <>
            <Modal
                isVisible={isVisible}
                onClose={closeModal}
                size='small'
                varient={varient}
                showFooter
                footerChildren={
                    <div>
                        <Button
                            size="small"
                            variant={"secondary"}
                            onClick={closeModal}
                        >
                            Close
                        </Button>
                    </div>
                }
            >
                <p>
                    {message}
                </p>
                {
                    txHash && txHash != "" ?
                        <p>
                            Transaction Hash : <a title='View on block explorer' target="_blank" style={{ color: '#17b7df' }} href={"https://rinkeby.etherscan.io/tx/" + txHash}>{txHash}</a>
                        </p>
                        :
                        <></>
                }

            </Modal>
        </>
    ) : null;
};
