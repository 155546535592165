import React, { HTMLAttributes, useEffect, useState } from 'react'
import { Button } from '../../atoms/button';
import { Loader } from '../../atoms/loader';
import { LedgerServiceFactory } from '../../ledger-service';
import { StatusModal } from '../../molecules/modal/status-modal';

import playerData from "../../json/card.json";
import { formatBalance } from '../../helpers/AmountUtil';

function RewardBar({rewardClaimedFromBuySell}: any) {
    const [rewardClaimed, setRewardClaimed] = useState(false);
    const [rewardAmount, setRewardAmount] = useState(0);
    const [rewardActive, setRewardActive] = useState(false);
    const [rewardData, setRewardData] = useState({ celebId: -1, celebTokenSymbol: "", celebIdFetched: false, start: 0, end: 0, totalRewardAmount: 0, totalRewardTokenSupply: 0 });
    const [statusModalData, setStatusModalData] = useState({ isVisible: false, isSuccess: false, msg: "", txHash: "" });
    const [showLoader, setShowLoader] = useState(false);

    const ledgerService = LedgerServiceFactory.getInstance();

    useEffect(() => {
        ledgerService.isRewardActive().then((rewardId: number) => {
            processActiveReward(rewardId);
        }).catch((ex) => {
            console.log(ex);
            setRewardActive(false);
        })
    }, [ledgerService]);

    useEffect(() => {
        if(rewardClaimedFromBuySell) {
            setRewardClaimed(true);
        }
    }, [rewardClaimedFromBuySell]);

    const claimReward = async () => {
        setShowLoader(true);
        ledgerService.claimRewards().then((result: any) => {
            console.log("result" + result);
            if (result.receipt.status === true) {
                setRewardClaimed(true);
                showStatusModal(true, true, "Reward claimed successfully!!", result.receipt.transactionHash);
            }
            else {
                console.log("receipt: false")
                showStatusModal(true, false, "Failed to claim reward!!", result?.receipt?.transactionHash);
            }
        }).catch(() => {
            showStatusModal(true, false, "Transaction rejected", "");
        }).finally(() => {
            setShowLoader(false);
        });
    };

    function processActiveReward(rewardId: number) {
        console.log("rewardId", rewardId);
        setRewardActive(true);
        fetchRewardData(rewardId);
        ledgerService.isRewardClaimed(rewardId).then((claimed: boolean) => {
            setRewardClaimed(claimed);
        });
    }

    function fetchRewardData(rewardId: number) {
        ledgerService.getRewardDetails(rewardId).then(async (reward) => {
            console.log("getRewardDetails", reward);
            if (reward.celebIdFetched) {
                const rewardedCelebBalance = +(await ledgerService.getCelebStarTokenBalance(reward.celebId));
                if (rewardedCelebBalance) {
                    const amt = reward.totalRewardAmount * rewardedCelebBalance / reward.totalRewardTokenSupply;
                    setRewardAmount(amt);
                }
                reward.celebTokenSymbol = playerData[+reward.celebId].symbol;
            }
            setRewardData(reward);
        });
    }

    function showStatusModal(isVisible: boolean, isSuccess: boolean, msg: string, txHash: string) {
        setStatusModalData({ isVisible, isSuccess, msg, txHash });
    }

    return (
        <>
            {showLoader ? <Loader loadFullPage /> : <></>}
            {
                rewardActive && !rewardClaimed && rewardData.celebIdFetched && (rewardAmount != 0) &&
                <div className="flex items-center justify-center p-5 m-1 top-bar">
                    <div className="flex items-center justify-center">
                        <p>
                            <p style={{"display": "flex"}}>
                                <p>
                                    Claim {formatBalance(rewardAmount)} TAL reward for {rewardData.celebTokenSymbol} token purchase
                                </p>
                                <Button
                                    isRounded
                                    size="xs"
                                    variant="primary"
                                    className="mr-1 uppercase ml-5"
                                    onClick={() => claimReward()}
                                >
                                    claim now
                                </Button>
                            </p>

                            <br />
                            <p style={{"fontSize": "0.9rem"}}>
                                **It will be automatically claimed if you Buy or Sell any tokens**
                                </p>
                        </p>

                    </div>
                </div>
            }

            {
                rewardActive && rewardClaimed && rewardData.celebIdFetched && (rewardAmount != 0) &&
                <div className="flex items-center justify-center p-5 m-1 top-bar">
                    <div className="flex items-center justify-center">
                        <p>
                            You have claimed {formatBalance(rewardAmount)} TAL for current active reward
                        </p>
                    </div>
                </div>
            }

            {
                rewardActive && rewardData.celebIdFetched && (rewardAmount === 0) &&
                <div className="flex items-center justify-center p-5 m-1 top-bar">
                    <div className="flex items-center justify-center">
                        <p>
                            {rewardData.celebTokenSymbol} token holders are rewarded
                        </p>
                    </div>
                </div>
            }
            <StatusModal
                isVisible={statusModalData.isVisible}
                onClose={() => showStatusModal(false, false, "", "")}
                varient={statusModalData.isSuccess ? "success" : "danger"}
                message={statusModalData.msg}
                txHash={statusModalData.txHash}
            ></StatusModal>
        </>
    )
}

export default RewardBar;