import React from "react";
import { SvgIcon } from "../../atoms/svgicons";

export interface MainHeaderProps {}

export const MainHeader = ({}: MainHeaderProps) => {
  return (
    <div className="flex flex-col w-full h-18">
      <div className="flex justify-between items-center flex-1 p-3 gradient-bg">
        <span className="text-lg tracking-wide leading-4 font-bold text-theme-white">
          Players
        </span>
        <SvgIcon
          width={35}
          height={35}
          fill="white"
          children={
            <>
              <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
              <path
                fill-rule="evenodd"
                d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
              />
            </>
          }
          viewBox="0 0 16 16"
        />
      </div>
    </div>
  );
};
