import fetchMock from "fetch-mock";
import { useEffect, useState } from "react";
import { Breadcrumb } from "../../atoms/breadcrumb";
import { Button } from "../../atoms/button";
import Icon from "../../atoms/icons/icon";
import { Card, CardBody, CardFooter } from "../../molecules/card";
import cardData from "../../json/card.json";
import { LedgerServiceFactory } from "../../ledger-service";
import { BuyModal } from "../../molecules/modal/buy-modal";
import { SellModal } from "../../molecules/modal/sell-modal";
import { StatusModal } from "../../molecules/modal/status-modal";
import { Loader } from "../../atoms/loader";
import {formatBalance} from "../../helpers/AmountUtil";

export const PlayerCard = (props: any) => {

  const [demoData, setDemoData] = useState<any[]>([]);
  const [showBuyModal, setShowBuyModal] = useState(false);
  const [showSellModal, setShowSellModal] = useState(false);
  const [statusModalData, setStatusModalData] = useState({ isVisible: false, isSuccess: false, msg: "", txHash: "" });
  const [selectedCeleb, setSelectedCeleb] = useState({id:0, player_name:"", symbol: ""});
  const [showLoader, setShowLoader] = useState(true);

  const ledgerService = LedgerServiceFactory.getInstance();

  useEffect(() => {
    ledgerService.initMetamask();
    ledgerService.getCountOfRegisteredCelebrities().then((count) => {
      //console.log("Count of registered celebrities:" + count);
      const players = cardData.filter(function (player: any) {
        return player.id < count;
      });
      setTokenBalance(players).then((updatedData) => {
        setDemoData(updatedData);
      }).finally(() => {
        setShowLoader(false);
      });
    });
  }, [ledgerService]);

  async function setTokenBalance(cardData: any) {
    return new Promise<any>((resolve, reject) => {
      try {
        ledgerService.fetchPlayersData(cardData).then(
          (updatedData) => {
            resolve(updatedData);
          }
        )
      } catch (error) {
        reject(error);
      }
    });
  };

  function showStatusModal(isVisible: boolean, isSuccess: boolean, msg: string, txHash: string) {
    setStatusModalData({ isVisible, isSuccess, msg, txHash });
  }

  function handleModalClose(event: any) {
    setShowBuyModal(false);
    setShowSellModal(false);
    if (event && event.status != undefined) {
      if (event.status) {
        setShowLoader(true);
        setTokenBalance(demoData).then((updatedData) => {
          setShowLoader(false);
          setDemoData(updatedData);
          showStatusModal(true, true, event.msg, event.result.receipt.transactionHash);
          const firstTimeMint = event.result.receipt.events.FirstTimeRewardEnabled;
          if (firstTimeMint) {
            props.onFirstTimeMint(firstTimeMint.returnValues);
          }
          const rewardClaimEvent = event.result.receipt.events.RewardClaimed;
          if (rewardClaimEvent) {
            props.onRewardClaim(rewardClaimEvent.returnValues);
          }
        }).finally(() => {
          setShowLoader(false);
        })
      } else {
        showStatusModal(true, false, event.msg, event?.result?.receipt?.transactionHash);
      }
    }
    console.log(event);
  }


  const myData = demoData ? (
    demoData.map((cardData: any) => {
      return (
        <>
          <Card
            cardClass="border hover:-translate-y-1 hover:scale-110 duration-300 cursor-pointer"
            shadow
          >
            <CardBody>
              <div className="flex justify-between">
                <img
                  src={cardData.profile_pic}
                  alt="player img"
                  width={80}
                  height={80}
                />
                <div className="card-right flex flex-col">
                  <a
                    href="#"
                    className="text-theme-secondary self-end transition ease-in-out delay-150 hover:transition-all text-lightSecondary"
                  >
                    <Icon
                      kind="star"
                      size={16}
                      className="text-theme-secondary"
                    />
                  </a>
                  <div>
                    <label className="text-xs uppercase text-theme-neutralLighter20">
                      token balance
                    </label>
                    <p className="font-semibold text-lg">
                      {formatBalance(cardData.balance)}
                      <span className="font-normal text-sm"> {cardData.symbol}</span>
                    </p>
                  </div>
                </div>
              </div>
            </CardBody>
            <CardFooter>
              <div className="flex justify-between items-center">
                <div className="flex items-center">
                  <div className="card-footer-img">
                    <img
                      src="https://tradestars.app/img/in.e4ab7bd0.svg"
                      alt=""
                    />
                  </div>
                  <div className=" ml-2">
                    <h1 className="font-bold text-base text-theme-neutralLighter20">
                      {cardData.player_name}
                    </h1>
                    <div className="flex flex-wrap items-center justify-between text-theme-neutralLighter20">
                      <Breadcrumb separator="." data={[{item: cardData.role}, {item: cardData.country}, {item: cardData.ipl_team}]}></Breadcrumb>
                    </div>
                  </div>
                </div>
                <div>
                  <Button
                    isRounded
                    size="xs"
                    variant="primary"
                    className="mr-1 uppercase"
                    onClick={() => {
                      setSelectedCeleb(cardData);
                      setShowBuyModal(true);
                    }}
                  >
                    buy
                  </Button>
                  <Button
                    isRounded
                    size="xs"
                    variant="primary"
                    className="uppercase"
                    onClick={() => {
                      setSelectedCeleb(cardData);
                      setShowSellModal(true);
                    }}
                  >
                    sell
                  </Button>
                </div>
              </div>
            </CardFooter>
          </Card>

        </>

      );
    })
  ) : (
    <div>Data Not Available!!</div>
  );
  return (
    <>
      {myData}
      {showLoader ? <Loader loadFullPage /> : <></>}
      <BuyModal
        isVisible={showBuyModal}
        celebId={selectedCeleb.id}
        celebName={selectedCeleb.player_name}
        celebSymbol={selectedCeleb.symbol}
        onClose={(event: any) => handleModalClose(event)}></BuyModal>
      <SellModal
        isVisible={showSellModal}
        celebId={selectedCeleb.id}
        celebName={selectedCeleb.player_name}
        celebSymbol={selectedCeleb.symbol}
        onClose={(event: any) => handleModalClose(event)}></SellModal>
      <StatusModal
        isVisible={statusModalData.isVisible}
        onClose={() => showStatusModal(false, false, "", "")}
        varient={statusModalData.isSuccess ? "success" : "danger"}
        message={statusModalData.msg}
        txHash={statusModalData.txHash}
      ></StatusModal>
    </>
  );
};
