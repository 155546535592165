import { ButtonHTMLAttributes } from "react";
import classNames from "classnames";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?: String;
  variant?: String;
  isRounded?: Boolean;
  isActive?: Boolean;
}

export const Button = ({
  size = "small",
  variant = "primary",
  children,
  disabled,
  className,
  isRounded,
  isActive,
  ...props
}: ButtonProps) => {
  return variant !== "link" ? (
    <button
      className={classNames(
        variant === "primary" && !disabled
          ? "bg-theme-primary text-theme-white hover:bg-theme-btnHover active:bg-theme-clicked"
          : variant === "primary" &&
              "bg-theme-disabled text-theme-neutralLighter60 pointer-events-none",
        variant === "secondary" && !disabled
          ? "border border-theme-primary hover:bg-theme-hover text-theme-primary active:bg-theme-clicked active:text-theme-white"
          : variant === "secondary" &&
              "border border-theme-neutralLighter20 text-theme-neutralLighter30 pointer-events-none",
        variant === "secondary-pill" && !disabled
          ? "inline-block px-6 py-2 border border-theme-primary text-blue-600 font-medium text-xs leading-tight uppercase hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
          : variant === "secondary-pill" &&
              "border border-theme-neutralLighter20 text-theme-neutralLighter30 pointer-events-none",
        size === "xs" && "py-1 px-2 text-xs",
        size === "small" && "py-1.5 px-2.5 text-sm",
        size === "medium" && "py-2 px-3 text-base",
        size === "large" && "py-3 px-4 text-base",
        className,
        isActive &&
          (variant === "primary"
            ? "bg-theme-clicked hover:bg-theme-btnHover"
            : variant === "secondary" &&
              "bg-theme-clicked hover:bg-theme-btnHover text-theme-white"),
        isRounded && "rounded",
        "rounded font-light btn"
      )}
      {...props}
    >
      {children}
    </button>
  ) : (
    <a
      className={classNames(
        !disabled
          ? "text-theme-primary  hover:underline hover:text-theme-btnHover active:text-theme-clicked active:no-underline cursor-pointer"
          : "text-theme-disabled pointer-events-none",
        size === "small" ? "text-sm" : "text-base",
        className,
        "font-light"
      )}
    >
      {children}
    </a>
  );
};
