import { HTMLAttributes, useState, useEffect } from "react";
import { Button } from "../../atoms/button";
import { Input } from "../../atoms/input";
import { Token } from '@lifi/sdk'
import { Loader } from "../../atoms/loader";
import { LedgerServiceFactory } from "../../ledger-service";
import { Modal } from "./modal";
import { formatBalance } from "../../helpers/AmountUtil";
import { WidgetConfig, LiFiWidget } from "@lifi/widget";

export interface BuyTALModalProps extends HTMLAttributes<HTMLElement> {
    isVisible?: boolean;
    onClose?: any;
}

export const BuyTALModal = ({
    isVisible,
    onClose,
    ...props
}: BuyTALModalProps) => {
    const [showModal, setShowModal] = useState(isVisible);
    const [showLoader, setShowLoader] = useState(false);

    const [ethBalance, setEthBalance] = useState(0);
    const [talBalance, setTalBalance] = useState(0);

    const [talConvertedValue, setTalConvertedValue] = useState(0);

    const ethConversionRate = 5000;

    const ledgerService = LedgerServiceFactory.getInstance();

    const [ethDepositAmt, setEthDepositAmt] = useState(0);

    const enableLifiWidget = process.env.REACT_APP_ENABLE_LIFI_WIDGET == 'true';

    useEffect(() => {
        setShowModal(isVisible);
        if (isVisible) {
            setTalConvertedValue(0);
            setEthDepositAmt(0);
            ledgerService.getTALTokenBalance().then((result) => {
                setTalBalance(+result);
            });
            ledgerService.getETHBalance().then((result) => {
                setEthBalance(+result);
            });
        }
    }, [isVisible]);

    function onInputChange(event: any) {
        let value = +event.target.value
        if (value && value >= 0) {
            setTalConvertedValue(value * ethConversionRate);
        } else {
            setTalConvertedValue(0);
        }
        setEthDepositAmt(value);
    }

    async function buyTalToken() {
        if (ethBalance < ethDepositAmt) {
            alert("Not enough ETH!");
            return;
        }
        if (ethDepositAmt && ethDepositAmt > 0) {
            setShowLoader(true);
            try {
                const result = await ledgerService.buyTALToken(ethDepositAmt);
                handleModalClose({ status: true, result });
            } catch (ex) {
                handleModalClose({ status: false, result: ex });
            }

            setShowLoader(false);
        } else {
            alert("ETH amount must be greater then 0.");
        }
    }

    function handleModalClose(event: any) {
        setShowModal(false);
        onClose(event);
    }

    const widgetConfig: WidgetConfig = {
        sdkConfig: {
            apiUrl: process.env.REACT_APP_LIFI_API_URL as string,
        },
        containerStyle: {
            borderRadius: '16px',
            boxShadow: '0px 8px 32px rgba(0, 0, 0, 0.08)',
        },
        variant: 'expandable',
        disableI18n: true,
        languages: {
            default: 'en',
        },
        appearance: 'light',
        disableAppearance: true,
        toToken: process.env.REACT_APP_TAL_CONTRACT_ADDRESS as string,
        toChain: parseInt(process.env.REACT_APP_CHAIN_ID as string, 16),
        disabledUI: ['toToken']
    };

    return showModal ? (
        <>
            {showLoader ? <Loader loadFullPage /> : <></>}

            <Modal
                modalTitle="Buy TAL Token"
                showClose={true}
                isVisible={showModal}
                onClose={(event: any) => handleModalClose(event)}
            >
                <div className="flex items-center flex-col">
                    <p className="text-base uppercase mb-3">
                        <label className="text-base font-medium">ETH Balance :</label>
                        {ethBalance && <span>{formatBalance(ethBalance)} ETH</span>}
                    </p>
                    <p className="text-base uppercase mb-3">
                        <label className="text-base font-medium">TAL Balance :</label>
                        {talBalance && <span>{formatBalance(talBalance)} TAL</span>}
                    </p>
                    {enableLifiWidget && <LiFiWidget config={widgetConfig} />}
                    {!enableLifiWidget &&
                        <div>
                            <div className="flex align-center mb-3">
                                <div className="w-40 mr-3">
                                    <Input
                                        label="ETH"
                                        placeholder="ETH"
                                        type="text"
                                        varient="small"
                                        onChange={(event: any) => { onInputChange(event) }}
                                    />
                                </div>
                                <div className="w-40">
                                    <Input
                                        label="TAL"
                                        placeholder="TAL"
                                        type="text"
                                        varient="small"
                                        disabled={true}
                                        value={formatBalance(talConvertedValue)}
                                    />
                                </div>
                            </div>

                            <Button
                                size="medium"
                                variant="primary"
                                className="w-80"
                                onClick={() => buyTalToken()}>
                                Buy
                            </Button>
                        </div>
                    }
                </div>
            </Modal>
        </>
    ) : null;
};
